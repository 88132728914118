import {
  AutocompleteInput,
  BooleanInput,
  Button,
  DeleteWithConfirmButton,
  Form,
  FormDataConsumer,
  Loading,
  NumberInput,
  ReferenceInput,
  SaveButton,
  useCheckAuth,
  useGetIdentity,
  useNotify,
  useRefresh,
} from 'react-admin';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AnyReportTriggerChannel,
  AssetGroupRef, ReportTrigger, ReportTriggerEmailChannel, Schedule, Zone, ZoneRef,
} from '@x-guard/xgac-types/xgac';
import {
  Card,
  Chip,
  Dialog, DialogContent, DialogTitle, IconButton, useTheme,
} from '@mui/material';
import { useGetMany, useTranslate } from 'ra-core';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import ReactApexChart from 'react-apexcharts';
import { useGetListLive } from '@react-admin/ra-realtime';
import AddIcon from '@mui/icons-material/Add';
import L from 'leaflet';
import uniqolor from 'uniqolor';
import ReactMarkdown from 'react-markdown';
import { MapContainer } from 'react-leaflet';
import { reportDataProvider } from '../../dataProviders/reportDataProvider';
import { getCurrentCustomer } from '../../lib/currentCustomer';
import { xgacDataProvider } from '../../dataProviders/xgacDataProvider';
import { MultipleChipInput } from '../inputs/MultipleChipInput';
import { emailArrayValidation } from '../../utils/emailArrayValidation';
import { ScheduleInput } from '../inputs/ScheduleInput';
import 'leaflet-toolbar';
import '@x-guard/xgac-leaflet-distortable-image';
import 'leaflet-toolbar/dist/leaflet.toolbar.css';
import { CustomLayerControl } from '../../apps/bhvk/components/LeafletControls';
import { uniqColorOptionsForZones } from '../maps/zoneMap';

const MonitorSharedFields = (props: {
  isBhvk?: boolean;
}) => {

  const translate = useTranslate();

  return (
    <>
      <span
        className="zone-overview-setting-span">{translate('resources.reports.text.zones_settings.occupancy_title')}
      </span>
      <NumberInput
        source="config.minOccupancy.assetCount"
        fullWidth
        max={50}
        sx={ props.isBhvk ? {
          '& input': {
            backgroundColor: '#f1f1f1',
            borderRadius: '5px',
          },
          '& fieldset': {
            border: 'none',
          },
        } : {}}
        label="resources.reports.fields.minOccupancy"/>
      <span
        className="zone-overview-setting-span">{translate('resources.reports.text.zones_settings.schedule')}
      </span>
      <p style={{ marginTop: '2px' }}>{translate('resources.reports.text.zones_settings.schedule_warning')}</p>
      <ScheduleInput
        source={'config.schedule.entries'}
      />
      <span
        className="zone-overview-setting-span">{translate('resources.reports.text.zones_settings.enabled')}
      </span>
      <BooleanInput
        source={'enabled'}
        label={'resources.reports.fields.enable_warnings'}
      />
      <FormDataConsumer>
        {({ formData }) => {

          if (!formData.enabled) {

            return null;

          }
          return (
            <>
              <MultipleChipInput
                source={'channels.0.address'}
                label="resources.report-triggers.fields.channels_address"
                validate={[emailArrayValidation]}
              />
            </>
          );

        }}
      </FormDataConsumer>
    </>
  );

};

const MonitorExtraZoneDialog = (props: {
  open: boolean;
  onClose: () => void;

}) => {

  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const { open, onClose } = props;

  const handleSubmit = (values: any) => {

    if (!values.zone) {

      return;

    }
    const data = {
      config: {
        type: 'zoneOverview',
        zones: [{
          _id: values.zone,
          _ref: 'Zone',
        }],
        minOccupancy: values.config.minOccupancy.assetCount ? {
          assetCount: values.config.minOccupancy.assetCount,
          ratioThreshold: 0.5,
        } : undefined,
        schedule: values.config.schedule,
        timeSeries: {
          timeframe: {
            type: 'hour',
            value: 1,
            floorTo: 'hour',
          },
          granularity: {
            type: 'minute',
            value: 5,
          },
        },
      },
      enabled: values.enabled,
      channels: values.enabled ? [
        {
          type: 'email',
          address: values.channels[0].address,
        },
      ] : [],
      scheduleTrigger: values.enabled ? {
        interval: {
          type: 'minute',
          value: 30,
        },
        cooldown: {
          type: 'day',
          value: 1,
        },
        nextAt: new Date().toISOString(),
        lastAt: null,
        lastAttemptedAt: null,
      } : null,
    };

    xgacDataProvider.create('report-triggers', {
      data,
    }).then(() => {

      notify(translate('resources.reports.text.zone_monitor_added'));
      refresh();
      onClose();

    });

  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" sx={{ marginTop: '-50px' }}>
        <DialogTitle className={'flex-in-between'}>
          <span>{translate('resources.reports.text.add_zone')}</span>
          <IconButton onClick={onClose}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Form
            defaultValues={{}}
            onSubmit={handleSubmit}
          >
            <span
              className="zone-overview-setting-span">{translate('resources.reports.text.zones_settings.zone_title')}
            </span>
            <ReferenceInput source="zone" reference="zones" required filter={{
              name: '!Opvolging wereld',
            }}
            queryOptions={{
              meta: {
                size: '5',
              },
            }}
            >
              <AutocompleteInput fullWidth isRequired/>
            </ReferenceInput>
            <MonitorSharedFields/>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 10,
            }}>
              <SaveButton
                label={'general.text.proceed'}
                icon={<></>}
                color={'secondary'}
              />
            </div>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );

};
const OverviewReportEditDialog = (props: {
  report: any;
  zone: Zone;
  open: boolean;
  onClose: () => void;
  needsCreate: boolean;
  originalReport?: any;
  removeDelete?: boolean;
}) => {

  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const handleSubmit = (values: any) => {

    if (props.needsCreate) {

      xgacDataProvider.update('report-triggers', {
        id: props.report._id,
        data: {
          ...props.report,
          config: {
            ...props.report.config,
            minOccupancy: values.config.minOccupancy.assetCount ? {
              assetCount: values.config.minOccupancy.assetCount,
              ratioThreshold: 0.5,
            } : undefined,
            zones: props.originalReport.config.zones.filter((zone: any) => zone._id !== props.zone._id),
          },
          enabled: values.enabled,
          channels: values.enabled ? [
            ...props.report.channels?.filter((channel: any) => channel.type !== 'email') || [],
            {
              type: 'email',
              address: values.channels[0].address,
            },
          ] : props.report.channels || [],
          scheduleTrigger: values.enabled ? {
            ...props.report.scheduleTrigger,
            interval: {
              type: 'minute',
              value: 30,
            },
            cooldown: {
              type: 'day',
              value: 1,
            },
            lastAt: props.report.scheduleTrigger?.lastAt || null,
            lastAttemptedAt: props.report.scheduleTrigger?.lastAttemptedAt || null,
            nextAt: new Date().toISOString(),
          } : null,
        },
        previousData: props.report,
      }).then(() => {

        notify('resources.reports.text.zone_monitor_updated');

      });
      xgacDataProvider.create('report-triggers', {
        data: {
          ...props.report,
          config: {
            ...props.report.config,
            ...values.config,
            zones: [{
              _id: props.zone._id,
              _ref: 'Zone',
            }],
          },
        },
      }).then(() => {

        refresh();
        props.onClose();

      });
      return;

    }
    xgacDataProvider.update('report-triggers', {
      id: props.report._id,
      data: {
        ...values,
        channels: values.enabled ? [
          {
            ...props.report.channels?.filter((channel: any) => channel.type !== 'email') || [],
            type: 'email',
            address: values.channels[0].address,
          },
        ] : [],
        scheduleTrigger: values.enabled ? {
          ...props.report.scheduleTrigger,
          interval: {
            type: 'minute',
            value: 30,
          },
          cooldown: {
            type: 'day',
            value: 1,
          },
          lastAt: props.report.scheduleTrigger?.lastAt || null,
          lastAttemptedAt: props.report.scheduleTrigger?.lastAttemptedAt || null,
          nextAt: new Date().toISOString(),
        } : null,
      },
      previousData: props.report,
    }).then(() => {

      refresh();
      props.onClose();

    });

  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle className={'flex-in-between'}>
        <span>{props.zone?.name || translate('resources.reports.text.settings')}</span>
        <IconButton onClick={props.onClose}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Form
          defaultValues={props.report}
          onSubmit={handleSubmit}
        >
          <MonitorSharedFields isBhvk={props.removeDelete}/>
          <div className="flex-in-between" style={{
            marginTop: 10,
          }}>
            {!props.removeDelete ? (
              <DeleteWithConfirmButton
                record={props.report}
                resource="report-triggers"
                confirmTitle={props.zone?.name || translate('resources.reports.title_single')} redirect={false}
              />
            )
              : <div></div>}
            <SaveButton
              label={'general.text.proceed'}
              icon={<></>}
              color={'secondary'}
              alwaysEnable/>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );

};

const ZoneGraphs = (props: {
  reports: {
    trigger: ReportTrigger & {
      config: {
        zones: ZoneRef[];
        includeGroups?: AssetGroupRef[];
        inactivityThreshold?: number;
        schedule?: Schedule;
        minOccupancy?: { assetCount: number; ratioThreshold: number };
      };
    };
    reportResult: {
      value: {
        zones: {
          zone: ZoneRef;
          occupancyHistory: {
            assetsInside: number;
            timestamp: string;
            assetIndexes: number[];
          }[];
        }[];
        requiredOccupancy: {
          occupancy: number;
          timestamp: string;
        }[];
        timeSeries: {
          startDate: string;
          endDate: string;
        };
        assets: {
          name: string;
        }[];
      };
    };
    needsCreate: boolean;
    originalReport: any;
  }[];
  hasElevation?: boolean;
}) => {

  const theme = useTheme();
  const translate = useTranslate();

  const zones = useGetMany('zones', { ids: props.reports.map((report) => report.trigger.config.zones[0]._id) });
  const zonesById = zones.data?.reduce((acc: any, zone: any) => {

    acc[zone._id] = zone;
    return acc;

  }, {}) || {};

  const ZoneMap = (data: {
    zone: Zone;
    showCounter?: number;
  }) => {

    const [map, setMap] = useState<any>(null);

    useEffect(() => {

      if (!map || !data.zone) {

        return;

      }

      map.eachLayer((layer: any) => {

        if (layer instanceof L.Polygon) {

          map.removeLayer(layer);

        }

      });
      const zone = data.zone.location;

      const color = uniqolor(
        data.zone._id,
        uniqColorOptionsForZones,
      );

      const zoneStyle = {
        color: color.color,
        fillColor: color.color,
        fillOpacity: 0.3,
        weight: 2,
      };

      const zoneToAdd = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: zone.coordinates,
        },
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const layer = L.geoJson(zoneToAdd, { style: zoneStyle });

      if (data.showCounter !== undefined) {

        layer.bindTooltip(`${data.showCounter}`, {
          permanent: true,
          direction: 'center',
          opacity: 1,
          className: 'zone-counter-tooltip',
        });

      }

      layer.addTo(map);

      map.fitBounds(layer.getBounds());

    }, [map, data.zone]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <MapContainer
        ref={(ref: any) => setMap(ref)}
        center={[52.1009166, 5.6462914]}
        zoom={17}
        minZoom={4}
        zoomControl={false}
        attributionControl={false}
        style={{ height: '300px', width: '250px' }}>
        <CustomLayerControl
          map={map}
          toggleVisible={false}
        />
      </MapContainer>
    );

  };
  const getChipEnabledColor = (isBhvk?: boolean) => {

    return isBhvk ? 'primary' : 'success';

  };

  const ZoneGraph = (data: {
    zone: Zone;
    occupancyHistory: { assetsInside: number; timestamp: string; assetIndexes: number[] }[];
    requiredOccupancy: { occupancy: number; timestamp: string }[];
    timeSeries: { startDate: string; endDate: string };
    assets: { name: string }[];
    report: { config: { zones: { _id: string }[] }; enabled: boolean; channels: AnyReportTriggerChannel[] };
    needsCreate: boolean;
    originalReport: { config: { zones: { _id: string }[] } };
    disableAdd?: boolean;
  }) => {

    const [editOpen, setEditOpen] = useState(false);

    // the data is in the form of {assetsInside: number, timestamp: string}. It should be converted to chart.js format
    const mappedData = data.occupancyHistory.map((value: any) => ({
      x: new Date(moment(value.timestamp).tz('Europe/Amsterdam').format()),
      y: value.assetsInside,
      assetIndexes: value.assetIndexes,
    }));

    let mappedRequiredOccupancy: {
      x: Date;
      y: number;
    }[] = [];
    if (data.requiredOccupancy.length > 1 || data.requiredOccupancy[0].occupancy > 0) {

      mappedRequiredOccupancy = data.requiredOccupancy.map((value: any) => ({
        x: new Date(moment(value.timestamp).tz('Europe/Amsterdam').format()),
        y: value.occupancy,
      }));

    }
    if (mappedRequiredOccupancy.length > 0) {

      mappedRequiredOccupancy.push({
        x: new Date(data.timeSeries.endDate),
        y: mappedRequiredOccupancy[mappedRequiredOccupancy.length - 1].y,
      });

    }

    // add first and last data point based on the min and max range
    if (mappedData.length > 0 && data.timeSeries) {

      mappedData.unshift({
        x: new Date(data.timeSeries.startDate),
        y: mappedData[0].y,
        assetIndexes: mappedData[0].assetIndexes,
      });

      mappedData.push({
        x: new Date(data.timeSeries.endDate),
        y: mappedData[mappedData.length - 1].y,
        assetIndexes: mappedData[mappedData.length - 1].assetIndexes,
      });

    }

    // get the min and max range of the data
    const minRange = mappedData[0].x;
    const maxRange = mappedData[mappedData.length - 1].x;

    const [dateRange, setDateRange] = useState({
      min: minRange,
      max: maxRange,
    });

    const longDateFormat = 'DD-MM-YYYY HH:mm';

    const maxY = Math.ceil(Math.max(
      ...[
        ...mappedData,
        ...mappedRequiredOccupancy,
      ].map((value: any) => value.y * 1.2),
    ));

    const onBeforeMount = () => {

      setDateRange(() => ({
        min: minRange,
        max: maxRange,
      }));

    };

    const onZoomed = (chartContext: any, { xaxis }: any) => {

      setDateRange(() => ({
        min: xaxis.min || minRange,
        max: xaxis.max || maxRange,
      }));

    };
    const getMinOccupancyLineColor = (hasElevation?: boolean) => {

      return hasElevation ? theme.palette.warning.main : theme.palette.success.main;

    };

    const reportIsEnabled = () => {

      if (!data.report.enabled) {

        return false;

      }
      const emailChannels = data.report.channels?.filter((channel: any) => channel.type === 'email') as ReportTriggerEmailChannel[];
      for (const channel of emailChannels) {

        if ((channel.address?.length || 0) > 0) {

          return true;

        }

      }
      return false;

    };

    const chart = useMemo(() => {

      return <ReactApexChart options={{
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: 'zoom',
            tools: {
              download: false,
              zoom: '<span></span>',
              pan: false,
              reset: 'Reset',
            },
          },
          events: {
            beforeMount: onBeforeMount,
            zoomed: onZoomed,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          colors: [theme.palette.primary.main, getMinOccupancyLineColor(props.hasElevation)],
          strokeWidth: 0,
          size: [5, 0],
          hover: {
            sizeOffset: 0,
          },
        },
        stroke: {
          curve: 'stepline',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          stepSize: 1,
          min: 0,
          max: maxY,
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false,
          },
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'dddd dd MMM',
              hour: 'HH:mm',
              minute: 'HH:mm:ss',
              second: 'HH:mm:ss',
            },
          },
        },
        tooltip: {
          shared: false,
          enabled: true,
          intersect: false,
          followCursor: true,
          x: {
            show: true,
            format: 'dddd dd MMM HH:mm',
            formatter: undefined,
          },
          custom: (opts) => {

            const {
              series, seriesIndex, dataPointIndex, w,
            } = opts;

            if (seriesIndex > 0) {

              return '';

            }

            const currentValue = series[seriesIndex][dataPointIndex];
            const currentTimestamp = w.config.series[seriesIndex].data[dataPointIndex].x;
            const assetsIndexes = w.config.series[seriesIndex].data[dataPointIndex].assetIndexes;

            return `<div>
            <div class="apexcharts-tooltip-title">
              ${moment(currentTimestamp).format(longDateFormat)}
            </div>
            <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
              <span class="apexcharts-tooltip-marker" style="background-color: ${theme.palette.primary.main}"></span>
              <div class="apexcharts-tooltip-text">
                <div class="apexcharts-tooltip-y-group">
                  <span class="apexcharts-tooltip-text-y-label">Bezetting: </span><span class="apexcharts-tooltip-text-y-value">${currentValue}</span>
                </div>
              </div>
            </div>
            ${assetsIndexes && assetsIndexes.length > 0 ? `
            <div style="padding: 5px">
              <strong>Aanwezig:</strong><br />
              ${assetsIndexes.map((index: number) => `- ${data.assets[index].name}`).join('<br />')}
            </div>` : ''}
          </div>`;

          },

        },
        colors: [
          theme.palette.primary.main,
          getMinOccupancyLineColor(props.hasElevation),
        ],
      }}
      series={[
        {
          name: translate('resources.reports.fields.occupancy'),
          data: mappedData,
        },
        {
          name: translate('resources.reports.fields.minOccupancy'),
          data: mappedRequiredOccupancy,
        },
      ]}
      type="area"
      height={350} />;

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Card className="zone-overview-div-container" elevation={props.hasElevation ? 2 : 0}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h3>
                {data.zone?.name}&nbsp;
                <small>({moment(dateRange.min).format(longDateFormat)} - {moment(dateRange.max).format(longDateFormat)})</small>
                <Chip
                  sx={{ marginLeft: '10px' }}
                  onClick={() => setEditOpen(true)}
                  size={'small'}
                  color={reportIsEnabled() ? getChipEnabledColor(props.hasElevation) : 'default'}
                  label={`${translate('resources.reports.text.sends_email')}: ${translate(`general.text.${reportIsEnabled()
                    ? 'enabled'
                    : 'disabled'}`).toLowerCase()}`}
                />
              </h3>
            </div>
            <div>
              <Button
                startIcon={<SettingsIcon />}
                onClick={() => setEditOpen(true)}
                label="general.text.settings"
                variant={props.hasElevation ? 'contained' : 'text'}
                size="small" />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginTop: '25px' }}>
              <ZoneMap zone={data.zone} showCounter={mappedData[mappedData.length - 1].y} />
            </div>
            <div style={{ flexGrow: 1 }}>
              {chart}
            </div>
          </div>
        </Card>
        <OverviewReportEditDialog
          report={data.report}
          zone={data.zone}
          open={editOpen}
          needsCreate={data.needsCreate}
          onClose={() => setEditOpen(false)}
          originalReport={data.originalReport}
          removeDelete={props.hasElevation}
        />
      </>
    );

  };

  return <>
    {props.reports.map((report, index) => {

      return report.reportResult.value?.zones.map((zone) => {

        return <div key={index}>
          <ZoneGraph
            zone={zonesById[zone.zone._id]}
            occupancyHistory={zone.occupancyHistory || []}
            requiredOccupancy={report.reportResult.value?.requiredOccupancy || []}
            timeSeries={report.reportResult.value?.timeSeries}
            assets={report.reportResult.value?.assets}
            report={report.trigger}
            needsCreate={report.needsCreate}
            originalReport={report.originalReport}
          />
        </div>;

      });

    })}

  </>;

};

export const ZoneOverview = (props: {
  disableAdd?: boolean;
}) => {

  type ReportTriggerState = {
    trigger: ReportTrigger & {
      config: {
        zones: ZoneRef[];
        assetGroups?: AssetGroupRef[];
        inactivityThreshold?: number;
        schedule?: Schedule;
        minOccupancy?: { assetCount: number; ratioThreshold: number };
      };
    };
    reportResult: any;
    needsCreate: boolean;
    originalReport: any;
  };

  const translate = useTranslate();
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  const currentCustomer = getCurrentCustomer();
  const identity = useGetIdentity();
  checkAuth();
  const [reportTriggers, setReportTriggers] = useState<ReportTriggerState[]>([]);
  const [savedCurrentCustomer, setSavedCurrentCustomer] = useState<string>(currentCustomer?.value || '');
  const [isLoading, setIsLoading] = useState(true);
  const [hasZones, setHasZones] = useState<boolean | null>(null);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const reportTriggerRequest = useGetListLive('report-triggers', {
    pagination: {
      page: 1,
      perPage: 100,
    },
    sort: {
      field: 'createdAt',
      order: 'DESC',
    },
    filter: {
      'config.type': 'zoneOverview',
    },
  });

  useEffect(() => {

    const getSettingsForTriggers = async () => {

      if (!reportTriggerRequest.data) {

        return;

      }

      const triggers: ReportTriggerState['trigger'][] = reportTriggerRequest.data;

      if (!reportTriggers || reportTriggers.length === 0) {

        setIsLoading(true);

      }
      const splitTriggers: ReportTriggerState[] = [];

      for (const currentTrigger of triggers) {

        for (const zone of currentTrigger.config.zones) {

          splitTriggers.push({
            trigger: {
              ...currentTrigger,
              config: {
                ...currentTrigger.config,
                zones: [zone],
              },
            },
            reportResult: await reportDataProvider.getZoneOverview(
              currentTrigger.config.assetGroups || [],
              [zone],
              {
                timeframe: {
                  type: 'week',
                  value: 1,
                  floorTo: 'minute',
                },
                granularity: {
                  type: 'minute',
                  value: 5,
                },
              },
              currentTrigger.config.inactivityThreshold,
              (currentTrigger.config.schedule?.entries?.length || 0) > 0
                ? currentTrigger.config.schedule
                : undefined,
              (currentTrigger.config.minOccupancy?.assetCount || 0) > 0
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                ? { assetCount: currentTrigger.config.minOccupancy!.assetCount, ratioThreshold: 0.5 }
                : undefined,
            ),
            needsCreate: currentTrigger.config.zones.length > 1,
            originalReport: currentTrigger,
          });

        }

      }

      const resolvedSettings = await Promise.all(splitTriggers);
      setIsLoading(false);

      // sort
      resolvedSettings.sort((a, b) => {

        if (a.trigger.config.zones[0]._id > b.trigger.config.zones[0]._id) {

          return 1;

        }
        if (a.trigger.config.zones[0]._id < b.trigger.config.zones[0]._id) {

          return -1;

        }

        return a.trigger.createdAt > b.trigger.createdAt ? -1 : 1;

      });

      setReportTriggers(resolvedSettings);

    };

    getSettingsForTriggers();

  }, [reportTriggerRequest.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    const getHasZonesAndGroups = async () => {

      const zones = await xgacDataProvider.getList('zones', {
        pagination: {
          page: 1,
          perPage: 1,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        filter: { name: '!Opvolging wereld' },
        meta: {
          size: '5',
        },
      });

      setHasZones(zones.total ? zones.total > 0 : false);

    };

    getHasZonesAndGroups();

  }, [savedCurrentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (currentCustomer?.value && currentCustomer?.value !== savedCurrentCustomer) {

      setSavedCurrentCustomer(currentCustomer?.value || '');

    }

  }, [identity, currentCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const zoneGraphs = useMemo(() => {

    if (!reportTriggers || !reportTriggerRequest.data) {

      return null;

    }

    return <ZoneGraphs reports={reportTriggers} hasElevation={props.disableAdd} />;

  }, [reportTriggers]); // eslint-disable-line react-hooks/exhaustive-deps

  if (hasZones === false) {

    return (
      <>
        <div className="zone-overview-no-data">
          <span>
            {translate('resources.reports.text.zones_no_groups')}
          </span>
          <Button onClick={() => navigate('/zone-map')} label="resources.zones.text.title" variant={'contained'} size="medium" sx={{
            marginTop: '10px',
          }}/>
        </div>
      </>
    );

  }

  if (isLoading) {

    return (
      <>
        <Loading sx={{
          height: '90% !important',
        }}
        loadingSecondary={'resources.reports.text.zone_monitor_loading_secondary'}
        />
      </>
    );

  }

  if (!reportTriggers || reportTriggers.length === 0) {

    return (
      <>
        {!props.disableAdd && (
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 10,
          }}>
            <Button
              label={translate('resources.reports.text.add_zone')}
              onClick={() => setSettingsOpen(true)}
              variant={'contained'}
              size="medium"
              startIcon={<AddIcon/>}/>
          </div>
        )}
        <div className="zone-overview-no-data">
          <ReactMarkdown>
            {translate('resources.reports.text.zones_no_data')}
          </ReactMarkdown>
          <Button
            sx={{
              marginTop: '1em',
            }}
            label={translate('resources.reports.text.add_zone')}
            onClick={() => setSettingsOpen(true)}
            variant={'contained'}
            size="medium"
            startIcon={<AddIcon/>}/>
        </div>
        <MonitorExtraZoneDialog open={settingsOpen} onClose={() => setSettingsOpen(false)}/>
      </>
    );

  }

  return (
    <div>
      {!props.disableAdd && (
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 10,
        }}>
          <Button
            label={translate('resources.reports.text.add_zone')}
            onClick={() => setSettingsOpen(true)}
            variant={'contained'}
            size="medium"
            startIcon={<AddIcon/>}/>
        </div>
      )}
      {zoneGraphs}
      <MonitorExtraZoneDialog open={settingsOpen} onClose={() => setSettingsOpen(false)}/>
    </div>
  );

};
