import {
  Contact,
  ContactList,
  ContactListRef,
  ReportTrigger,
  ReportTriggerChannelType,
  ReportTriggerRun,
  TimeGranularityType,
} from '@x-guard/xgac-types/xgac';
import {
  Confirm, Identifier, useLocaleState, useRefresh,
} from 'react-admin';
import React, {
  Dispatch, SetStateAction, SyntheticEvent, useEffect, useState,
} from 'react';
import { useTranslate } from 'ra-core';
import _ from 'lodash';
import momentTZ from 'moment-timezone';
import moment from 'moment';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/en-gb';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Autocomplete, Card, CardContent, Checkbox, Chip, TextField, useTheme,
} from '@mui/material';
import { useGetListLive } from '@react-admin/ra-realtime';
import Grid2 from '@mui/material/Unstable_Grid2';
import { xgacDataProvider } from '../dataProviders/xgacDataProvider';
import { getCurrentCustomer } from '../lib/currentCustomer';
import { emailArrayValidation } from '../utils/emailArrayValidation';
import { simpleReportTransformer } from '../utils/reportDefaults';
import { AttachmentModal } from '../apps/xgac/entities/reportTriggerRun';

const Grid = Grid2;

const ReportEnabledSetterField = (props: {
  record: any;
  setLastSaved: Dispatch<SetStateAction<Date | false | 'saving' | null>>;
  theme?: string;
  timezone: string;
}) => {

  const refresh = useRefresh();
  const record = props.record;
  const translate = useTranslate();
  const initialValue = record.enabled || false;
  const [value, setValue] = useState(initialValue);
  const [isSaving, setIsSaving] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const currentLocale = useLocaleState();
  moment.locale(currentLocale[0]);

  useEffect(() => {

    setValue(record.enabled);

  }, [record]);

  const updateTrigger = async (checkedValue: boolean) => {

    setIsConfirmOpen(false);
    setIsSaving(true);
    props.setLastSaved('saving');

    setValue(checkedValue);
    if (record.id) {

      const patchData = {
        enabled: checkedValue,
        scheduleTrigger: record.scheduleTrigger,
        channels: record.channels,

      };
      if (checkedValue && !['inactivityMonitor', 'newAlarm'].includes(record.config.type)) {

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        patchData.scheduleTrigger = {
          ...patchData.scheduleTrigger,
          nextAt: record.config.type !== 'appUsage'
            ? momentTZ.tz(props.timezone)
              .add(1, 'week')
              .startOf('week')
              .add(1, 'day')
              .add(8, 'hours')
              .toISOString()
            : momentTZ.tz(props.timezone)
              .add(1, 'week')
              .startOf('month')
              .toISOString(),
        };

      }

      await xgacDataProvider.update('report-triggers', { id: record._id, data: patchData, previousData: record });
      props.setLastSaved(new Date());
      setIsSaving(false);
      refresh();
      return;

    }

    const transformedData = await simpleReportTransformer({
      ...record,
    });

    const postData = {
      ...transformedData,
      enabled: checkedValue,
      ...(props.theme ? {
        config: {
          ...transformedData.config,
          theme: props.theme,
        },
      } : {}),
    };
    await xgacDataProvider.create('report-triggers', { data: postData });
    props.setLastSaved(new Date());
    setIsSaving(false);
    refresh();

  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

    if (event.target.checked) {

      await updateTrigger(true);
      return;

    }
    setIsConfirmOpen(true);

  };
  const handleLabelClick = async () => {

    if (!value) {

      await updateTrigger(true);
      return;

    }
    setIsConfirmOpen(true);

  };

  if (isSaving) {

    return (
      <>
        <div style={{ minHeight: '42px' }}>
          <CircularProgress size={20}/>
        </div>
      </>
    );

  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox checked={value} onChange={handleChange} key={`${record.id}-checkbox`} style={{ marginLeft: -10 }} />
        <span onClick={handleLabelClick} style={{ cursor: 'pointer' }}>{translate(value ? 'general.text.enabled' : 'general.text.disabled')}</span>
        {(value && !['inactivityMonitor', 'newAlarm'].includes(record.config.type)) && (
          <Chip
            disabled={!record.enabled}
            label={
              `${translate('resources.report-triggers.fields.nextRunAt')} ${moment(record.scheduleTrigger?.nextAt).format('dddd D MMMM')}`
            }
            sx={{ marginLeft: '10px' }}
            size="small"
          />
        )}
      </div>
      <Confirm
        title={'ra.message.are_you_sure'}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={() => updateTrigger(false)}
        content={'resources.reports.text.confirm_dialog_content'}
        isOpen={isConfirmOpen}
      />
    </>
  );

};

const ReportRecipientsSetterField = (props: { record: any; setLastSaved: Dispatch<SetStateAction<Date | false | 'saving' | null>>; timezone: string }) => {

  const translate = useTranslate();
  const [fieldError, setFieldError] = useState<string | undefined>(undefined);
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);

  const theme = useTheme();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [addressFieldValue, setAddressFieldValue] = useState<string[]>(props.record.channels[0]?.address || []);

  useEffect(() => {

    setAddressFieldValue(props.record.channels[0]?.address || []);

  }, [props.record.channels]);
  useEffect(() => {

    if (props.record.channels[0]?.contactLists?.length > 0) {

      const contactListIds = props.record.channels[0]?.contactLists.map((contactListRef: ContactListRef) => contactListRef._id);

      xgacDataProvider.getMany('contact-lists', { ids: contactListIds }).then((contactLists) => {

        const addresses = contactLists.data.map(
          (contactList: ContactList) => contactList.contacts.map(
            (contact: Contact) => contact.email,
          ),
        ).flat().filter(
          (email: string | null) => email,
        ) as string[];
        if (addresses.length > 0) {

          setAddressFieldValue(addresses);

        }

      });

    }

  }, [props.record.channels]);

  useEffect(() => {

    if (!hasUpdated) {

      return;

    }
    props.setLastSaved('saving');
    if (props.record.id) {

      if (!fieldError) {

        const updateData = {
          channels: [
            {
              ...props.record.channels[0],
              contactLists: undefined,
              address: addressFieldValue,
            },
          ],
          ...(props.record.channels[0].address.length === 0 && {
            scheduleTrigger: props.record.scheduleTrigger ? {
              ...props.record.scheduleTrigger,
              nextAt: momentTZ.tz(props.timezone)
                .add(2, 'minutes')
                .toISOString(),
            } : undefined,
          }),
        };

        xgacDataProvider.update('report-triggers', {
          id: props.record.id,
          data: updateData,
          previousData: props.record,
        }).then(() => {

          props.setLastSaved(new Date());

        }).catch(() => {

          props.setLastSaved(false);

        });

      } else {

        props.setLastSaved(false);

      }

    }

  }, [addressFieldValue, fieldError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const recordAddresses = props.record.channels[0]?.address || [];
    if (!_.isEqual(recordAddresses, addressFieldValue)) {

      const hasError = emailArrayValidation(addressFieldValue);
      setFieldError(hasError);
      if (hasError) {

        props.setLastSaved(false);

      }

    }

  }, [props.record]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (props?.record?.channels[0]?.type !== ReportTriggerChannelType.Email) {

      setFieldDisabled(true);

    } else {

      setFieldDisabled(!props.record.id);

    }

  }, [props]);

  const handleChange = (event: SyntheticEvent, value: string[]) => {

    if (!hasUpdated) {

      setHasUpdated(true);

    }
    event.preventDefault();
    setFieldError(emailArrayValidation(value));
    setAddressFieldValue(value);

  };

  return (
    <Autocomplete
      multiple
      color={fieldError ? 'error' : 'primary'}
      options={[]}
      handleHomeEndKeys
      defaultValue={ addressFieldValue || []}
      freeSolo
      disabled={fieldDisabled}
      autoSelect
      value={addressFieldValue}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange={handleChange}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderTags={(renderValue: readonly string[], getTagProps) => renderValue.map((option: string, index: number) => (
        <Chip variant="filled" size="small" label={option} {...getTagProps({ index })} />
      ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          hiddenLabel={true}
          error={fieldError !== undefined}
          // eslint-disable-next-line no-nested-ternary
          helperText={addressFieldValue.length > 0 ? fieldError ? translate(fieldError) : undefined : translate('resources.reports.text.no_recipients')}
          sx={{
            '& .MuiFormHelperText-root': {
              marginLeft: '0',
              color: theme.palette.error.main,
            },
          }}
        />
      )}
    />);

};

export const ReportCard = (props: {
  report?: ReportTrigger & { id: Identifier };
  createType?: string;
  setLastSaved: Dispatch<SetStateAction<Date | false | 'saving' | null>>;
  theme?: string;
  timezone: string;
}) => {

  const [lastReportRun, setLastReportRun] = useState<ReportTriggerRun | null>(null);

  useEffect(() => {

    setLastReportRun(null);
    if (props.report?._id) {

      xgacDataProvider.getList('report-trigger-runs', {
        filter: {
          'reportTrigger._id': props.report._id,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        pagination: {
          page: 1,
          perPage: 1,
        },
      }).then((reportRuns) => {

        setLastReportRun(reportRuns.data[0] || null);

      }).catch(() => {

        setLastReportRun(null);

      });

    }

  }, [props.report]);
  const passedReport = props.report || {
    channels: [
      {
        type: ReportTriggerChannelType.Email,
        address: [],
      },
    ],
    scheduleTrigger: {
      nextAt: momentTZ.tz(props.timezone)
        .add(1, 'week')
        .startOf('week')
        .add(1, 'day')
        .add(8, 'hours')
        .toISOString(),
      interval: ['appUsage', 'unhealthyBeacons'].includes(props.createType || '') ? {
        type: TimeGranularityType.Month,
        value: 1,
      } : {
        type: TimeGranularityType.Week,
        value: 1,
      },
      lastAt: null,
      lastAttemptedAt: null,
    },
    enabled: false,
    config: {
      ...(props.createType === 'inactivityMonitor' ? {
        filter: {},
        lastSeenThreshold: {
          value: 16,
          type: 'hour',
        },
        timeout: {
          value: 1,
          type: 'hour',
        },
      } : {}),
      type: props.createType,
    },
  };

  const translate = useTranslate();
  const theme = useTheme();
  const borderColor = () => {

    if (props.report?.enabled) {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const emails = props.report.channels[0]?.address || [];
      if (emails.length > 0 && !emailArrayValidation(emails)) {

        return theme.palette.success.main;

      }

    }
    return theme.palette.error.main;

  };
  return (<>
    <Card variant='outlined' sx={{ borderTop: `4px solid ${borderColor()}`, height: '100%' }}>
      <CardContent>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3>{translate(`resources.report-triggers.text.config_types.${props.createType || props?.report?.config.type}`)}</h3>
          <AttachmentModal
            source='channelResults'
            label="resources.reports.fields.last_attachments"
            buttonLabel="resources.reports.fields.last_attachments"
            record={lastReportRun}
          />
        </div>
        <p style={{
          minHeight: '6em',
        }}>{translate(
            `resources.report-triggers.text.config_types.subtext.${props.createType || props?.report?.config.type}`,
            {
              interval: translate(
                `resources.reports.text.subtext_${props.report?.scheduleTrigger?.interval.type || 'week'}`,
                { smart_count: props.report?.scheduleTrigger?.interval.value || 1 },
              ),
              _: '',
            },
          )}
        </p>
        <div>
          <ReportEnabledSetterField record={passedReport} setLastSaved={props.setLastSaved} theme={props.theme} timezone={props.timezone}/>
        </div>
        <h4 style={{ marginTop: '20px', marginBottom: '5px' }}>{translate('resources.reports.text.recipients')}</h4>
        <div>
          <ReportRecipientsSetterField record={passedReport} setLastSaved={props.setLastSaved} timezone={props.timezone}/>
        </div>
      </CardContent>
    </Card>
  </>);

};

export const ReportCardList = (props: {
  possibleReportTypes: string[];
  setLastSaved: Dispatch<SetStateAction<Date | false | 'saving' | null>>;
  theme?: string;
}) => {

  const { possibleReportTypes, setLastSaved } = props;
  const [allData, setAllData] = useState<any>([]);
  const [customerTimeZone, setCustomerTimeZone] = useState<string>('Europe/Amsterdam');

  const { data, isLoading } = useGetListLive('report-triggers', {
    sort: { field: 'createdAt', order: 'ASC' },
    pagination: { page: 1, perPage: 1000 },
  });

  useEffect(() => {

    const customer = getCurrentCustomer()?.value;
    if (customer) {

      xgacDataProvider.getOne('customers', { id: customer }).then((customerData) => {

        if (customerData.data?.timeZone) {

          setCustomerTimeZone(customerData.data.timeZone);

        }

      });

    }

  }, [data]);

  useEffect(() => {

    const reportTypesToFilter = ['alarmCenterStatus', 'zoneOverview'];
    const unusedReportTypes = possibleReportTypes.filter((type) => !data?.find((report: ReportTrigger & { id: Identifier }) => report.config.type === type));
    const filteredData = data?.filter((report: ReportTrigger & { id: Identifier }) => !reportTypesToFilter.includes(report.config.type));
    setAllData([...filteredData || [], ...unusedReportTypes.map((type) => ({ config: { type } }))]
      .sort((a: { config: { type: string } }, b: { config: { type: string } }) => {

        if (possibleReportTypes.indexOf(a.config.type) === -1) {

          return 1;

        }
        if (possibleReportTypes.indexOf(b.config.type) === -1) {

          return -1;

        }
        return possibleReportTypes.indexOf(a.config.type) - possibleReportTypes.indexOf(b.config.type);

      }));

  }, [data, possibleReportTypes]);

  return (
    <>
      {(!data && isLoading) && (
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
        }}>
          <CircularProgress/>
        </div>)}
      <div style={{ height: '100%', marginTop: '10px' }}>
        <Grid container spacing={2}>
          {allData && allData.map((report: any) => {

            if (report.id) {

              return <Grid xs={12} md={6}><ReportCard report={report} setLastSaved={setLastSaved} timezone={customerTimeZone}/></Grid>;

            }
            return <Grid xs={12} md={6}>
              <ReportCard createType={report.config.type} setLastSaved={setLastSaved} theme={props.theme} timezone={customerTimeZone}/>
            </Grid>;

          })}
        </Grid>
      </div>
    </>
  );

};
